import { tsImportEqualsDeclaration } from "@babel/types";
import React from "react";
import logo from "../assets/images/noozai-logo-fullcolor.png";

class Nooz extends React.Component {
  componentDidMount() {
    document.title = "Nooz. AI";
  }

  handleLearnMore() {
    console.log("Will handle learn more");
    return alert(
      "We can rediect to another page or another website with info about this"
    );
  }

  handleNotify() {
    return alert("Add the email to db");
  }

  render() {
    return (
      <div
        className="row"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label style={this.styles.welcome}>Welcome</label>
        <img src={logo} alt="logo" />

        <label style={this.styles.releaseLabel}>
          We will be up and running on soon
        </label>

        {/* <label style={this.styles.countDownLabel}>
          01 days | {this.handleDisplay(this.state.minutes)} mins |{" "}
          {this.handleDisplay(this.state.seconds)} seconds
        </label> */}

        <div
          className="column"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            marginTop: 30,
          }}
        >
          {/* to handle the learn more */}
          {/* <a onClick={this.handleLearnMore} style={this.styles.link}>
            Learn More
          </a>

          <label style={this.styles.releaseLabel}>|</label> */}

          {/* to handle the notify */}
          {/* <a 
            onClick={this.handleNotify}
            style={this.styles.link}
          >
            Notify me when available
          </a> */}
        </div>
      </div>
    );
  }

  styles = {
    welcome: {
      textAlign: "center",
      fontSize: 30,
      fontWeight: "800",
      color: "#20579E",
    },
    releaseLabel: {
      textAlign: "center",
      marginTop: 0,
      fontSize: 22,
      color: "#20579E",
      fontWeight: "600",
    },
    countDownLabel: {
      textAlign: "center",
      marginTop: 0,
      fontSize: 30,
      color: "#20579E",
      fontWeight: "800",
    },
    link: {
      cursor: "pointer",
      textAlign: "center",
      color: "blue",
      fontSize: 18,
      margin: 15,
    },
  };
}

export default Nooz;
