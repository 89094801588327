import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../assets/images/icon_idgroup_default.png";

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <img
          style={{ marginRight: 6 }}
          src={logo}
          alt=""
          width="24"
          height="24"
        />

        <Link className="navbar-brand" to="/" src={logo}>
          Nooz. AI
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <NavLink
              className="nav-link"
              to="/connect"
              target="_blank"
              rel="noopener noreferrer"
            >
              netTALK Connect
            </NavLink>
            <NavLink
              className="nav-link"
              to="/pbx"
              target="_blank"
              rel="noopener noreferrer"
            >
              netTALK PBX
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
