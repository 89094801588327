import React from "react";

const NotFound = () => {
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1>404 Not Found</h1>
    </div>
  );
};

export default NotFound;
