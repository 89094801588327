import logo from "./logo.svg";
import "./App.css";
import { Route, Redirect, Switch } from "react-router-dom";
import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
import React from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import Nooz from "./components/nooz";

function App() {
  return (
    <React.Fragment>
      <NavBar />
      <main className="container">
        <Switch>
          <Route
            path="/connect"
            component={() => {
              window.location.href = "https:nettalkconnect.com";
              return null;
            }}
          />
          <Route
            path="/pbx"
            component={() => {
              window.location.href = "https://nettalkbusiness.com";
              return null;
            }}
          />
          <Route path="/nooz" component={Nooz} />
          {/* <Route path="/register" component={RegisterForm} />
          <Route path="/login" component={LoginForm} />
          <Route path="/movies/:id" component={MovieForm} />
          <Route path="/movies" component={Movies} />
          <Route path="/customers" component={Customers} />
          <Route path="/rentals" component={Rentals} /> */}
          <Route path="/not-found" component={NotFound} />
          <Redirect from="/" exact to="nooz" />
          <Redirect to="/not-found" />
        </Switch>
      </main>
    </React.Fragment>
  );
}

export default App;
